import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import User from '@mui/icons-material/AccountCircle';
import AdminPanel from '@mui/icons-material/AdminPanelSettings';
import Logout from '@mui/icons-material/Logout';
import Fastfood from '@mui/icons-material/Fastfood';
import Category from '@mui/icons-material/Category';
import About from '@mui/icons-material/Info';
import Menu from '@mui/icons-material/MenuBook';
import Cart from '@mui/icons-material/ShoppingBag';
import Extension from '@mui/icons-material/Extension';
import LocalDining from '@mui/icons-material/LocalDining';
import Payment from '@mui/icons-material/Payment';
import ListAlt from '@mui/icons-material/ListAlt';
import TuneIcon from '@mui/icons-material/Tune';
import Info from '@mui/icons-material/Info';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ROUTES from '../routes';
import { useNavigate } from "react-router-dom";
import { Divider, IconButton } from '@mui/material';
import Switch from '@mui/material/Switch'; // Importa Switch
import store from '../store';
import { changeMode } from '../store/actions/userActions';
import QRCodeForm from '../components/Admin/TablesQRCodes/QRCodeForm';
import CachedIcon from '@mui/icons-material/Cached';

import { getLabel, getOrderingEnabledStatus, setOrderingEnabledStatus } from '../apis/common';
import { forceTranslations } from '../apis/admin';
import SuccessToast from '../components/common/SuccessToast';
import FailedToast from '../components/common/FailedToast';
import InfoToast from '../components/common/InfoToast';

function DrawerNavigation({ open, toggleDrawer, logo }) {

  const [labels, setLabels] = React.useState([]);
  const [openSuccess, setOpenSucess] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const [text, setText] = React.useState("");

  const getTranslatedLabel = (languageKey) => {
    const languageItem = labels.find((obj) => obj.language_key === languageKey);
    return languageItem ? languageItem.translated_language : '';
  };

  const [orderingEnabled, setOrderingEnabled] = React.useState(true);

  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const fetchOrderingEnabledStatus = async () => {
      try {
        // Utilizza getOrderingEnabledStatus per ottenere lo stato
        const status = await getOrderingEnabledStatus();
        console.log('Ordering enabled status:', status);
        setOrderingEnabled(status);
      } catch (error) {
        console.error('Error fetching ordering status:', error);
      }
    };

    fetchOrderingEnabledStatus();
  }, []);


  const toggleOrderingMode = async () => {
    //event.stopPropagation();

    const newStatus = !orderingEnabled;

    console.log('Toggling order mode to', newStatus);

    try {
      const response = await setOrderingEnabledStatus(newStatus);
      if (response && response.code === 200) {
        setOrderingEnabled(newStatus);
      } else {
        console.error('Failed to toggle order mode', response);
      }
    } catch (error) {
      console.error('Error toggling order mode:', error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSucess(false);
    setOpenInfo(false);
    setOpenFailed(false);
  }

  const orderModeSwitchItem = {
    icon: <Payment />,
    label: orderingEnabled ? "Order Mode ON" : "Order Mode OFF",
    actionItem: (
      <Switch
        checked={orderingEnabled}
        onChange={toggleOrderingMode}
        onClick={(event) => event.stopPropagation()}
        name="orderMode"
      />
    ),
  };

  const adminItems = [

    orderModeSwitchItem,

    {
      icon: <Fastfood />,
      label: "Edit Products",
      route: ROUTES.ADMIN.PRODUCTS,
    },
    {
      icon: <Category />,
      label: "Edit Categories",
      route: ROUTES.ADMIN.CATEGORIES,
    },
    {
      icon: <Extension />,
      label: "Edit Addons",
      route: ROUTES.ADMIN.ADD_ONS,
    },
    {
      icon: <TuneIcon />,
      label: "Edit Variants",
      route: ROUTES.ADMIN.VARIANTS,
    },
    {
      icon: <TuneIcon />,
      label: "Edit Variants Groups",
      route: ROUTES.ADMIN.GROUPS,
    },
    {
      icon: <LocalDining />,
      label: "Edit Taste Profile",
      route: ROUTES.ADMIN.TASTE_PROFILES,
    },
    {
      icon: <LocalDining />,
      label: "Edit Ingredients",
      route: ROUTES.ADMIN.INGREDIENTS,
    },
    // {
    //   icon: <Payment />,
    //   label: "Edit Payment Methods",
    //   route: ROUTES.ADMIN.PAYMENT_METHODS,
    // },
    {
      icon: <Info />,
      label: "Edit Business info",
      route: ROUTES.ADMIN.BUSINESS_INFO,
    },
    {
      icon: <ListAlt />,
      label: "View Orders",
      route: ROUTES.ADMIN.ORDERS,
    },

    {
      icon: <ListAlt />,
      label: "Language Key",
      route: ROUTES.ADMIN.LANGUAGE_KEY,
    },
    {
      icon: <ListAlt />,
      label: "Translate Language",
      route: ROUTES.ADMIN.TRANSLATE_LANGUAGE,
    },
    {
      icon: <CachedIcon />,
      label: "Force translation update",
      api: "https://casagrandeapi.personalwaiter.it/translatesync",
    },
    {
      icon: <ListAlt />,
      label: "CSS Template",
      route: ROUTES.ADMIN.CSS_TEMPLATE,
    },
  ]


  React.useEffect(() => {
    // Potresti voler aggiungere qui una funzione per caricare lo stato iniziale dell'ordinazione
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const storedLanguage = localStorage.getItem('selectedLanguage');
        const response = await getLabel(storedLanguage || 'English');
        if (response.code === 200) {
          setLabels(response.result);
          console.log(response.result)

        }
      } catch (error) {
        console.log('Error fetching language data:', error);
      }
    };

    fetchData()

  }, [])

  const userItems = [
    // pages
    {
      icon: <User />,
      label: getTranslatedLabel('user'),
      route: '/user/0', // -> Favorites, Order history, Taste profile
    },
    {
      icon: <Menu />,
      label: getTranslatedLabel('menu'),
      route: ROUTES.COMMON.HOME,
    },
    {
      icon: <Cart />,
      label: getTranslatedLabel('cart'),
      route: ROUTES.USER.CHECKOUT,
    },
    // {
    //   icon: <About />,
    //   label: getTranslatedLabel('about'),
    //   route: ROUTES.COMMON.ABOUT,
    // },
    {
      divider: true,
      component: <Divider />,
    },
  ]

  const navigate = useNavigate();
  const state = store.getState();
  const handleClick = (route) => {
    navigate(route);
  }

  const logout = () => {
    store.dispatch(changeMode("user"));
    navigate(ROUTES.COMMON.HOME)
  }

  const handleForceTranslations = async () => {
    setOpenInfo(true);
    setText("Force translations started!")
    const code = await forceTranslations()
    if (code === 200) {
      setOpenSucess(true);
      setText("Force translations success!")
    } else {
      setOpenFailed(true);
      setText("Force translations failed!")
    }
  }

  const list = () => (
    <Box
      sx={{ width: 280 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Box
        component="img"
        sx={{ height: 70, width: 70, marginLeft: 12, marginTop: 5, cursor: "pointer" }}
        alt="Logo"
        src={logo}
        onClick={() => { navigate(ROUTES.COMMON.HOME) }}
      />
      <List>
        {
          state.mode !== "user" && adminItems.map((obj, index) => {
            return (
              obj.divider ? obj.component :
                <ListItem
                  key={obj.label}
                  disablePadding
                  //onClick={() => { obj.label === "Logout" ? logout() : handleClick(obj.route) }}
                  onClick={
                    obj.route ?
                      () => handleClick(obj.route) :
                      obj.api ? handleForceTranslations :
                        null
                  }
                >
                  {/* <ListItemButton>
                    <ListItemIcon>
                      {obj.icon}
                    </ListItemIcon>
                    <ListItemText primary={obj.label} />
                  </ListItemButton> */}
                  <ListItemButton>
                    <ListItemIcon>{obj.icon}</ListItemIcon>
                    {obj.label && <ListItemText primary={obj.label} />}
                    {obj.actionItem && obj.actionItem}
                  </ListItemButton>
                </ListItem>
            );
          })
        }
        {
          state.mode !== "user" && state.isSuper &&
          <ListItem key={"Manage Admins"} disablePadding onClick={() => { navigate("manageAdmins") }}>
            <ListItemButton>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={"Manage Admins"} />
            </ListItemButton>
          </ListItem>
        }
        {
          state.mode !== "user" && <Divider />
        }
        {
          userItems.map((obj, index) => {
            return (
              obj.divider ? obj.component :
                <ListItem key={obj.label} disablePadding onClick={() => { obj.label === "Logout" ? logout() : handleClick(obj.route) }}>
                  <ListItemButton>
                    <ListItemIcon>
                      {obj.icon}
                    </ListItemIcon>
                    <ListItemText primary={obj.label} />
                  </ListItemButton>
                </ListItem>
            );
          })
        }

        {
          state.mode !== "user" &&
          <ListItem disablePadding>
            <ListItemButton onClick={(event) => {
              //event.stopPropagation(); // Prevent the click from closing the drawer
              setShowModal(true); // Open the modal
            }}>
              <ListItemIcon><Payment /></ListItemIcon>
              <ListItemText primary="Generate Tables QRCodes" />
            </ListItemButton>
          </ListItem>
        }

        {
          state.mode === "user" ? <ListItem key={"Admin Panel"} disablePadding onClick={() => { handleClick(ROUTES.ADMIN.LOGIN) }}>
            <ListItemButton>
              <ListItemIcon>
                <AdminPanel />
              </ListItemIcon>
              <ListItemText primary={getTranslatedLabel('admin_panel')} />
            </ListItemButton>
          </ListItem> :
            <ListItem key={"Logout"} disablePadding onClick={() => { logout() }}>
              <ListItemButton>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
        }
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          open={open}
          onClose={toggleDrawer}
        >
          {list()}
        </Drawer>
        {showModal && (
          <QRCodeForm open={showModal} handleClose={() => setShowModal(false)} />
        )}
      </React.Fragment>
      <SuccessToast open={openSuccess} handleClose={handleClose} text={text} />
      <InfoToast open={openInfo} handleClose={handleClose} text={text} />
      <FailedToast open={openFailed} handleClose={handleClose} text={text} />
    </div>
  );
}

export default DrawerNavigation;
